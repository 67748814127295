import config from "../config";
import axios from '../libs/axios'

const redirect_url = `${window.location.protocol}//${window.location.hostname
    }${window.location.port ? ":" + window.location.port : window.location.port
    }`;

export default {
    namespaced: true,
    state: {
        account: null,
        msalConfig: {
            auth: {
                clientId: config.clientId,
                authority:
                    `${config.authDomain}/${config.userflow[config.authType].signin}`,
                knownAuthorities: [config.knownAuthorities], // You must identify your tenant's domain as a known authority.
                redirectUri: redirect_url, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
            },
            cache: {
                cacheLocation: 'localStorage',
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        // console.log('loggerCallback', message);
                    },
                    piiLoggingEnabled: false
                },
            }
        },
        accessToken: "",
        loadingAuthStatus: true,
    },
    getters: {
        getAccount(state) {
            return state.account;
        },
        getLoadingAuthStatus(state) {
            return state.loadingAuthStatus;
        }
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setLoadingAuthStatus(state, loadingAuthStatus) {
            state.loadingAuthStatus = loadingAuthStatus;
        }
    },
    actions: {
        setLoadingAuthStatus({ commit }, loadingAuthStatus) {
            commit('setLoadingAuthStatus', loadingAuthStatus);
        },
        async signOut({ commit }, msalInstance, emitter) {
            localStorage.removeItem("user_uptime");
            await msalInstance
                .logout({})
                .then(() => {
                    emitter.emit("logout", "logging out");
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        signUp({ commit }, msalInstance, emitter) {
            const redirect_url = `${window.location.protocol}//${window.location.hostname
                }${window.location.port ? ":" + window.location.port : window.location.port
                }`;

            const signupUrl = `${config.authDomain}/oauth2/v2.0/authorize?p=${config.userflow[config.authType].signup
                }&client_id=${config.clientId}&nonce=defaultNonce&redirect_uri=${redirect_url}&scope=openid&response_type=code&prompt=login&code_challenge=32f3224`;

            window.location.href = signupUrl;
        }
    },
}
