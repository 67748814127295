import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        principals: [],
        loadingPrincipals: false,
    },
    getters: {
        principals: state => {
            return state.principals;
        },
    },
    mutations: {
        ADD_NEW_PRINCIPAL(state, val) {
            state.principals = [...state.principals, val]
        },
        EDIT_PRINCIPAL(state, val) {
            state.principals = state.principals.map(principal => (principal.id === val.id ? val : principal))
        },
        SET_PRINCIPALS(state, val) {
            state.principals = val
        },
        SET_LOADING_PRINCIPALS(state, val) {
            state.loadingPrincipals = val
        },
    },
    actions: {
        get(ctx, bvToast) {
            ctx.commit("SET_LOADING_PRINCIPALS", true)

            axios.get(`/principals`).then((res) => {
                ctx.commit("SET_PRINCIPALS", res.data)
                ctx.commit("SET_LOADING_PRINCIPALS", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_PRINCIPALS", false)
                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        store(ctx, principal) {
            return axios.post(`/principals`, principal);
        },
    },
}
