import Vue from 'vue'

// axios
import axios from 'axios'

// const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_PROD
const baseURL = process.env.VUE_APP_API_URL_PROD

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: `${baseURL}api/`,
    // timeout: 1000,
    headers: {
        'Content-Type': "application/json"
    }
})

axiosIns.interceptors.response.use(response => {
    return response;
}, error => {
    console.log('error', error)
    console.log('error.response', error.response)

    if (error.response === undefined) {
        throw new Error("Cannot connect to API backend");
    } else if (error.Message === "Network Error") {
        throw new Error("Cannot connect to API backend");
    } else if (error.response.status === 401) {
        throw new Error("API Request is not authorized");
    } else if (error.response.status === 500) {
        throw new Error(error.response?.data);
    } else {
        error.message = error.Message
        throw new Error(error);
    }
});

Vue.prototype.$http = axiosIns

export default axiosIns
