import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        scenes: [],
        unassigned_scenes: [],
        loadingScenes: false,
    },
    getters: {
        scenes: state => {
            return state.scenes;
        },
    },
    mutations: {
        ADD_NEW_SCENE(state, val) {
            state.scenes = [...state.scenes, val]
        },
        EDIT_SCENE(state, val) {
            state.scenes = state.scenes.map(scene => (scene.id === val.id ? val : scene))
        },
        SET_SCENES(state, val) {
            state.scenes = val
        },
        SET_UNASSIGNED_SCENES(state, val) {
            state.unassigned_scenes = val
        },
        SET_LOADING_SCENES(state, val) {
            state.loadingScenes = val
        },
    },
    actions: {
        get(ctx, params) {
            ctx.commit("SET_LOADING_SCENES", true)
            const url = params?.deviceId ? `/scenes?deviceId=${params?.deviceId}` : `/scenes`
            axios.get(url).then((res) => {
                if (params?.deviceId == "unassigned") {
                    ctx.commit("SET_UNASSIGNED_SCENES", res.data)
                } else {
                    ctx.commit("SET_SCENES", res.data)
                }
                ctx.commit("SET_LOADING_SCENES", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_SCENES", false)

                if (params?.bvToast) {
                    params.bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        store(ctx, scene) {
            return axios.post(`/scenes`, scene);
        },
        remove(ctx, scene) {
            var sceneName = scene.name
            var parkingLotName = scene.parkingLot?.name
            var principalName = scene.parkingLot?.principal?.name
            return axios.delete(`/scenes?scene=${sceneName}&parkinglot=${parkingLotName}&principal=${principalName}`);
        },
    },
}
