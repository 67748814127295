import axios from '../libs/axios'

export default {
    namespaced: true,
    state: {
        selectedParkingLot: null,
        selectedPrincipal: null,
    },
    getters: {
    },
    mutations: {
        SET_GLOBAL_PARKINGLOT(state, val) {
            state.selectedParkingLot = val
        },
        SET_GLOBAL_PRINCIPAL(state, val) {
            state.selectedPrincipal = val
        },
    },
    actions: {
        setGlobalParkingLot(ctx, parkingLot) {
            ctx.commit("SET_GLOBAL_PARKINGLOT", parkingLot)
        },
        setGlobalPrincipal(ctx, principal) {
            ctx.commit("SET_GLOBAL_PRINCIPAL", principal)
        },
    },
}
