import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        devices: [],
        loadingDevices: false,
        occupancys: [],
        loadingOccupancys: false,
    },
    getters: {
        devices: state => {
            return state.devices;
        },
    },
    mutations: {
        ADD_NEW_DEVICE(state, val) {
            state.devices = [...state.devices, val]
        },
        EDIT_DEVICE(state, val) {
            state.devices = state.devices.map(device => (device.id === val.id ? val : device))
        },
        SET_DEVICES(state, val) {
            state.devices = val
        },
        SET_LOADING_DEVICES(state, val) {
            state.loadingDevices = val
        },
        SET_OCCUPANCYS(state, val) {
            state.occupancys = val
        },
        SET_LOADING_OCCUPANCYS(state, val) {
            state.loadingOccupancys = val
        },
    },
    actions: {
        get(ctx, bvToast) {
            ctx.commit("SET_LOADING_DEVICES", true)

            axios.get(`/devices`).then((res) => {
                ctx.commit("SET_DEVICES", res.data)
                ctx.commit("SET_LOADING_DEVICES", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_DEVICES", false)
                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        getOccupancys(ctx, bvToast) {
            ctx.commit("SET_LOADING_OCCUPANCYS", true)

            axios.get(`/occupancy`).then((res) => {
                ctx.commit("SET_OCCUPANCYS", res.data)
                ctx.commit("SET_LOADING_OCCUPANCYS", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_OCCUPANCYS", false)
                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        getSnapshot(ctx, params) {
            return axios.get(`/devices/snapshot?deviceStreamKey=${params.device_id}&commandGuid=${params.commandGuid}`);
        },
        setCommand(ctx, params) {
            return axios.post(`/devices/command`, params);
        },
        store(ctx, device) {
            return axios.post(`/devices`, device);
        },
    },
}
