import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Emitter from "tiny-emitter";
import { PublicClientApplication } from "@azure/msal-browser";
import VueMoment from "vue-moment";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import * as VueGoogleMaps from 'vue2-google-maps'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue Moment
Vue.use(VueMoment)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$emitter = new Emitter();
Vue.prototype.$msalInstance = new PublicClientApplication(
  store.state.auth.msalConfig
);;

// google map
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCEdsh2v6Ay7iDyyjyRmAKcK7QPPf-YS-8',
    libraries: 'places',
  },
  installComponents: true
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
