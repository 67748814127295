<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <div id="loading-bg" v-if="loadingAuthStatus">
      <div class="loading-logo">
        <img src="/logo.png" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
    <component v-else :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { BSpinner } from "bootstrap-vue";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

import { PublicClientApplication } from "@azure/msal-browser";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BSpinner,
  },
  data() {
    return {
      account: undefined,
      errMsg: "dddd",
    };
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    loadingAuthStatus: {
      get() {
        return this.$store.getters["auth/getLoadingAuthStatus"];
      },
      set(val) {
        this.$store.dispatch("auth/setLoadingAuthStatus", val);
      },
    },
  },
  async created() {
    this.loadingAuthStatus = true;

    this.$msalInstance
      .handleRedirectPromise()
      .then(this.handleResponse)
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(err.message, {
          title: `Error occured`,
          variant: "danger",
          noAutoHide: true,
          solid: true,
          toaster: "b-toaster-bottom-right",
        });
      });
  },
  mounted() {
    this.handleAccount();
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  methods: {
    handleAccount() {
      const accounts = this.$msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return;
      }

      this.account = accounts[0];
      this.$store.commit("auth/setAccount", this.account);
      this.$emitter.emit("login", this.account);
    },

    handleResponse(redirectResponse) {
      if (window.location.href.indexOf("/signup") != -1) {
        return;
      }
      // if (this.$route.path == "/signup" || this.$route.path == "/signin") {
      //   return;
      // }
      // return;
      console.log("redirectResponse", redirectResponse);

      if (redirectResponse !== null) {
        this.loadingAuthStatus = false;

        const myAccounts = this.$msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        this.$store.commit("auth/setAccount", this.account);
        this.$store.commit("auth/setAccessToken", redirectResponse.accessToken);

        this.$emitter.emit("login", this.account);
        localStorage.setItem("user_uptime", JSON.stringify(myAccounts[0]));
        this.$router.push("/");
      } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = this.$msalInstance.getAllAccounts()[0];

        const accessTokenRequest = {
          scopes: [
            "openid",
            "https://SmartALot.onmicrosoft.com/a75d4edb-0233-47b6-8369-576233b5287b/access_as_user",
          ],
          account: account,
        };
        // return;
        const that = this;
        that.$msalInstance.acquireTokenRedirect(accessTokenRequest);
        // this.$msalInstance
        //   .acquireTokenSilent(accessTokenRequest)
        //   .then(function (accessTokenResponse) {
        //     // Acquire token silent success
        //     // Call API with token
        //     let accessToken = accessTokenResponse.accessToken;
        //     // Call your API with token
        //     console.log("accessToken", accessToken);
        //   })
        //   .catch(function (error) {
        //     //Acquire token silent failure, and send an interactive request
        //     console.log(error);
        //     that.$msalInstance.acquireTokenRedirect(accessTokenRequest);
        //   });
      }
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>

<style>
.action-btns .btn-sm{
  padding: 0.486rem 0.5rem!important;
}
</style>