import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        zones: [],
        loadingZones: false,
    },
    getters: {
        zones: state => {
            return state.zones;
        },
    },
    mutations: {
        SET_ZONES(state, val) {
            state.zones = val
        },
        SET_LOADING_ZONES(state, val) {
            state.loadingZones = val
        },
    },
    actions: {
        get(ctx, bvToast) {
            ctx.commit("SET_LOADING_ZONES", true)
            axios.get(`/zones`).then((res) => {
                ctx.commit("SET_ZONES", res.data)
                ctx.commit("SET_LOADING_ZONES", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_ZONES", false)

                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
    },
}
