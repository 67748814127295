import Vue from 'vue'
import VueRouter from 'vue-router'
import config from "../config";

Vue.use(VueRouter)

function makeRandom(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scenes',
      name: 'scenes',
      component: () => import('@/views/Scenes.vue'),
      meta: {
        pageTitle: 'Scenes',
        breadcrumb: [
          {
            text: 'Scenes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/parkinglots',
      name: 'parkinglots',
      component: () => import('@/views/ParkingLots.vue'),
      meta: {
        pageTitle: 'Parking Lots',
        breadcrumb: [
          {
            text: 'Parking Lots',
            active: true,
          },
        ],
      },
    },
    {
      path: '/spaces',
      name: 'spaces',
      component: () => import('@/views/Spaces.vue'),
      meta: {
        pageTitle: 'Spaces',
        breadcrumb: [
          {
            text: 'Spaces',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/Devices.vue'),
      meta: {
        pageTitle: 'Devices',
        breadcrumb: [
          {
            text: 'Devices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    ,
    {
      path: '/signup',
      name: 'Signup',
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '*',
    //   redirect: 'error-404',
    // },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.name == "Signup") {
    const redirect_url = `${window.location.protocol}//${window.location.hostname
      }${window.location.port ? ":" + window.location.port : window.location.port
      }`;

    const code_challenge = makeRandom(10)

    const signupUrl = `${config.authDomain}/oauth2/v2.0/authorize?p=${config.userflow[config.authType].signup}&client_id=${config.clientId}&nonce=defaultNonce&redirect_uri=${redirect_url}&scope=openid&response_type=code&prompt=login&code_challenge=${code_challenge}`;
    window.location.href = signupUrl;

  } else {
    next(); return;

    if (to.matched.some(record => record.meta.requiresAuth)) {
      let user_uptime = localStorage.getItem('user_uptime')
      let user = user_uptime == "undefined" ? null : JSON.parse(user_uptime)

      if (user == null) {
        next({
          path: '/',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
