import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        parkingLots: [],
        loadingParkingLots: false,
    },
    getters: {
        parkingLots: state => {
            return state.parkingLots;
        },
    },
    mutations: {
        ADD_NEW_PARKINGLOT(state, val) {
            state.parkingLots = [...state.parkingLots, val]
        },
        EDIT_PARKINGLOT(state, val) {
            state.parkingLots = state.parkingLots.map(parkingLot => (parkingLot.id === val.id ? val : parkingLot))
        },
        SET_PARKINGLOTS(state, val) {
            state.parkingLots = val
        },
        SET_LOADING_PARKINGLOTS(state, val) {
            state.loadingParkingLots = val
        },
    },
    actions: {
        get(ctx, bvToast) {
            ctx.commit("SET_LOADING_PARKINGLOTS", true)

            axios.get(`/parkinglots`).then((res) => {
                ctx.commit("SET_PARKINGLOTS", res.data)
                ctx.commit("SET_LOADING_PARKINGLOTS", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_PARKINGLOTS", false)

                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        store(ctx, parkingLot) {
            return axios.post(`/parkinglots`, parkingLot);
        },
        remove(ctx, parkingLot) {
            return axios.delete(`/parkinglots?parkinglot=${parkingLot.parkingLot}&principal=${parkingLot.principal}`);
        },
    },
}
