import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import global from './global'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import scene from './scene'
import parkingLot from './parkinglot'
import principal from './principal'
import auth from './auth'
import space from './space'
import zone from './zone'
import device from './device'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    global,
    appConfig,
    verticalMenu,
    scene,
    parkingLot,
    principal,
    auth,
    space,
    zone,
    device,
  },
  strict: process.env.DEV,
})
