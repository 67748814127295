export default {
    clientId: "a75d4edb-0233-47b6-8369-576233b5287b",
    clientSecret: "vlm7Q~UYx9gHN~olkunSgS_LeTYEB-jJNO-7Y",
    authDomain: "https://SmartALot.b2clogin.com/SmartALot.onmicrosoft.com",
    knownAuthorities: 'SmartALot.b2clogin.com',
    userflow: {
        email: {
            signin: 'B2C_1_SmartALot_SignIn',
            signup: 'B2C_1_SmartALot_SignUp',
        },
        username: {
            signin: 'B2C_1_SmartALot_SignIn',
            signup: 'B2C_1_SmartALot_SignUp',
        },
    },
    authType: "username" // email | username
}