import axios from '../../libs/axios'

export default {
    namespaced: true,
    state: {
        spaces: [],
        originSpaces: [],
        loadingSpaces: false,
    },
    getters: {
        spaces: state => {
            return state.spaces;
        },
    },
    mutations: {
        ADD_NEW_SPACE(state, val) {
            state.spaces = [...state.spaces, val]
        },
        EDIT_SPACE(state, val) {
            state.spaces = state.spaces.map(space => (space.id === val.id ? val : space))
        },
        SET_SPACES(state, val) {
            if (val == "originSpaces") {
                let temp = []
                for (let i = 0; i < state.originSpaces.length; i++) {
                    const item = state.originSpaces[i];
                    const ttmp = JSON.parse(JSON.stringify(item))

                    temp.push(ttmp)
                }
                state.spaces = temp
            } else {
                state.spaces = val
            }
        },
        SET_ORIGIN_SPACES(state, val) {
            state.originSpaces = val
        },
        SET_LOADING_SPACES(state, val) {
            state.loadingSpaces = val
        },
    },
    actions: {
        get(ctx, bvToast) {
            ctx.commit("SET_LOADING_SPACES", true)
            axios.get(`/spaces`).then((res) => {
                ctx.commit("SET_SPACES", [...res.data])
                let temp = []
                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    const ttmp = JSON.parse(JSON.stringify(item))

                    temp.push(ttmp)
                }
                ctx.commit("SET_ORIGIN_SPACES", temp)
                ctx.commit("SET_LOADING_SPACES", false)
            }).catch((err) => {
                ctx.commit("SET_LOADING_SPACES", false)

                if (bvToast) {
                    bvToast.toast(err.message, {
                        title: `Error occured`,
                        variant: 'danger',
                        noAutoHide: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    })
                }
            });
        },
        store(ctx, space) {
            return axios.post(`/spaces`, space);
        },
        remove(ctx, space) {
            var spaceName = space.name
            var parkingLotName = space.parkingLot?.name
            var principalName = space.parkingLot?.principal?.name
            ctx.commit("SET_LOADING_SPACES", true)
            return axios.delete(`/spaces?space=${spaceName}&parkinglot=${parkingLotName}&principal=${principalName}`);
        },
    },
}
